import React from 'react'
import SiteCard from '../components/SiteCard'

export default function Portfolio() {
	return (
		<div className='Portfolio' id='portfolio'>
			<div className='portfolio-header'>
				<img className='caret' alt='caret' src='/images/logos/arrow.svg' />
				<h2>portfolio</h2>
			</div>
			<div className='site-cards'>
			<SiteCard
					id='wallet'
					title='Capital One Wallet'
					url='https://c1-digital.netlify.app/'
				/>
				<SiteCard
					id='paypal'
					title='Capital One Paypal'
					url='https://www.capitalone.com/digital/digital-payments/paypal/'
				/>
				<SiteCard
					id='digital'
					title='Capital One Digital'
					url='https://www.capitalone.com/digital/digital-payments/'
				/>
				<SiteCard
					id='mobile'
					title='Capital One Mobile'
					url='https://www.capitalone.com/digital/mobile/'
				/>
				{/* <SiteCard
					id='d2'
					title='D2Runebook • Vue, Express, Node & MongoDB'
					url='https://d2runebook.herokuapp.com/'
				/> */}
				<SiteCard
					id='todo-list'
					title='Todo List • HTML, CSS, JavaScript'
					url='https://soft-pony-6078f8.netlify.app'
				/>
				<SiteCard
					id='weather'
					title='Weather API App • HTML, CSS, JavaScript'
					url='https://dulcet-muffin-89a1d8.netlify.app'
				/>
			</div>
		</div>
	)
}
