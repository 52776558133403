import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'

function Experience(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3, padding: '10px 15px' }}>
					<Typography
						component={'div'}
						sx={{
							fontFamily: 'gothamlight',
							fontSize: '18px',
							fontWeight: 'bold',
						}}
					>
						{children}
					</Typography>
				</Box>
			)}
		</div>
	)
}

Experience.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	}
}

export default function ExperienceTabs() {
	const [value, setValue] = React.useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<div className='Experience' id='experience'>
			<div className='experience-header'>
				<img className='caret' alt='caret' src='/images/logos/arrow.svg' />
				<h2>experience</h2>
			</div>
			<Box
				sx={{
					flexGrow: 1,
					display: isMobile ? 'block' : 'flex',
					minHeight: isMobile ? '475px' : '200px',
					opacity: 0,
					animation: 'fadeIn 2s forwards, slideInFromBottom 1s forwards',
					animationDelay: '8s',
				}}
			>
				<Tabs
					orientation={!isMobile ? 'vertical' : 'horizontal'}
					variant='scrollable'
					value={value}
					onChange={handleChange}
					aria-label='Work history'
					textColor='inherit'
					indicatorColor='primary'
					sx={{
						borderRight: 1,
						borderColor: 'divider',
						minWidth: '200px',
						opacity: 0,
						animation: 'fadeIn 2s forwards, slideInFromBottom 1s forwards',
						animationDelay: '7s',
					}}
				>
					<Tab
						className='experience-tab'
						label='Capital One'
						{...a11yProps(0)}
					/>
					<Tab className='experience-tab' label='Elephant' {...a11yProps(1)} />
					<Tab className='experience-tab' label='Unboxed' {...a11yProps(2)} />
				</Tabs>

				{workExperience.map((item, index) => {
					return (
						<Experience
							className='experience-container'
							value={value}
							index={index}
							key={item.title}
						>
							<div className='experience-panel-header'>
								<Link to={item.url} target='_blank'>
									{item.title}
								</Link>
								<span className='timespan'>{item.timespan}</span>
							</div>
							<ul className='job-description-list'>
								{item.duties.map((item, i) => {
									return (
										<li className='job-list-item' key={i}>
											<img
												className='caret-bullet'
												alt='caret'
												src='/images/logos/arrow.svg'
												key={i}
											/>
											<span key={item.desc}>{item}</span>
										</li>
									)
								})}
							</ul>
						</Experience>
					)
				})}
			</Box>
		</div>
	)
}

const isMobile = window.innerWidth < 600
const workExperience = [
	{
		company: 'Capital One',
		title: 'Front End Software Engineer',
		timespan: 'February 2018 - June 2023',
		duties: [
			'Developed SPAs & microsites for marketing teams across Capital One with Vue & React',
			'Equipped with state management, routing, event propagation, CMS integration & analytics',
			'Mentor for the Capital One Coders program, supporting junior programmers & students',
		],
		url: 'https://www.capitalonecareers.com/'
	},
	{
		company: 'Elephant',
		title: 'Jr Software Engineer',
		timespan: 'April 2017 - February 2018',
		duties: [
			'Developed features for the Guidewire software in Java & SQL',
			'Created Angular components for our UX library & Wordpress site',
			'Created a Java batch process to automatically retry failed messages queues',
		],
		url: 'https://www.elephant.com/careers'
	},
	{
		company: 'Unboxed',
		title: 'Intern Software Engineer',
		timespan: 'June 2016 - August 2016',
		duties: [
			'Contributed to development of the Spoke application in AngularJS',
			'Created a NodeJS app to generate thumbnails & spreadsheets for QA',
			'Assisted senior developers with tech debt, bug fixing and feature development',
		],
		url: 'https://unboxedtechnology.com/careers/'
	},
]
