import React from 'react'

export default function SiteCard({ id, url, title }) {
	return (
		<div className='site-card' id={id}>
			<a
				href={url}
				aria-label='site card link'
				target='_blank'
				rel='noindex noreferrer'
			>
				{' '}
				<div className='card__footer flex-center-all'>
					<span>{title}</span>
				</div>
			</a>
		</div>
	)
}
