
import './styles/main.css'
import React from 'react'
import About from './views/About'
import Skills from './views/Skills'
import Portfolio from './views/Portfolio'
import Experience from './views/Experience'
// import Footer from './components/Footer'

export default function App() {
	return (
		<div className='App'>
			<About />
			<Skills />
			<Experience />
			<Portfolio />
			{/* <Footer /> */}
		</div>
	)
}
