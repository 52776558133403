import React from 'react'

export default function Skills() {
	return (
		<div className='Skills' id='skills'>
			<div className='skills-header'>
				<img className='caret' alt='caret' src='/images/logos/arrow.svg' />
				<h2>skills</h2>
			</div>
			<ul className='logos'>
				{logos.map((image, index) => {
					return (
						<a
							href={image.url}
							key={index}
							target='_blank'
							rel='noopener noreferrer'
						>
							<img
								alt={image.name + '-logo'}
								className='logo'
								key={index}
								src={getImagePath(index)}
							></img>
						</a>
					)
				})}
			</ul>
		</div>
	)
}

function getImagePath(index) {
	return pathPrefix + logos[index].name + ext
}

const pathPrefix = '/images/logos/'
const ext = '.svg'
const logos = [
	{
		name: 'javascript',
		url: 'https://www.javascript.com/',
	},
	{
		name: 'vue',
		url: 'https://vuejs.org/',
	},
	{
		name: 'react',
		url: 'https://reactjs.org/',
	},
	{
		name: 'html',
		url: 'https://html.com/html5/',
	},
	{
		name: 'sass',
		url: 'https://sass-lang.com/',
	},
	{
		name: 'node',
		url: 'https://nodejs.org/en/',
	},
	{
		name: 'aws',
		url: 'https://aws.amazon.com/',
	},
	{
		name: 'docker',
		url: 'https://www.docker.com/',
	},
	{
		name: 'mongo',
		url: 'https://www.mongodb.com/',
	},
	{
		name: 'adobe',
		url: 'https://www.adobe.com/creativecloud.html',
	},
]
